import React from 'react'
import ColumnContent from './ColumnContent'

const RelatedProject = props => {

  const data = props.data

  return (
    <section className={`section wrapper rpc`}>
      <div className={`grid col-2`}>
        {data.relatedProjects && data.relatedProjects.map((project, i) => (
          <ColumnContent {...project} key={i} />
        ))}
      </div>
    </section>
  )
}

export default RelatedProject
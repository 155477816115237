import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { Helmet } from 'react-helmet'
import ProjectBuilder from "../../components/ProjectBuilder"
import ProjectNavigation from '../../components/ProjectNavigation'

const Project = props => {

  const pageData = props.data
  const hero = pageData.project.acfProjectBuilder && pageData.project.acfProjectBuilder.components.filter(component => component.__typename === 'WpProject_Acfprojectbuilder_Components_Hero')
  const seoImg = hero !== null && hero.length  > 0 ? hero[0].heroImage ? hero[0].heroImage.localFile.url : undefined : undefined

  return (
    <Layout>
      <Seo
        title={pageData.project.title}
        seoTitle={pageData.project.seo.title}
        description={pageData.project.seo.metaDesc}
        seoImage={seoImg}
      />
      <Helmet bodyAttributes={{ class: `project single` }} />
      <article>
        <ProjectBuilder {...props} />
        <ProjectNavigation prev={pageData.previous} next={pageData.next} />
      </article>
    </Layout>
  )
}

export default Project

export const query = graphql`
  query Project(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    project: wpProject(id: {eq: $id}) {
      ...SingleProjectFragment
    }
    previous: wpProject(id: {eq: $previousPostId}) {
      uri
    }
    next: wpProject(id: {eq: $nextPostId}) {
      uri
    }
  }
`
import React from 'react'
import ContentComponent from './ContentComponent'
import Hero from './Hero'
import ProjectCarousel from './ProjectCarousel'
import RelatedProject from './RelatedProject'

const ProjectBuilder = props => {

  const pageData = props.data.project || props.data.featuredProjects

  return (
    <>
      {pageData.acfProjectBuilder.components && (
        <>
          {pageData.acfProjectBuilder.components.map((component, i) => {
            //if (component && component.fieldGroupName) {
              const type = component.fieldGroupName

              const componentProps = {
                data: component,
                pageData,
                props,
              }

              const Default = () => (<div><pre>In Project Builder, the mapping of this component is missing: <strong>{type}</strong></pre></div>)

              const componentList = {
                project_Acfprojectbuilder_Components_Hero: Hero,
                project_Acfprojectbuilder_Components_ContentSection: ContentComponent,
                project_Acfprojectbuilder_Components_ProjectCarousel: ProjectCarousel,
                project_Acfprojectbuilder_Components_RelatedProjects: RelatedProject,
                page_default: Default
              }

              const ComponentTag = componentList[type] ? componentList[type] : componentList["page_default"]

              return (
                <ComponentTag {...componentProps} key={i} />
              )
            //}
          })}
        </>
      )}
    </>
  )
}

export default ProjectBuilder
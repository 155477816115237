import React from 'react'
import {Link} from 'gatsby'

const ProjectNavigation = ({prev, next}) => {

  return (
    <section className={`section pn wrapper flex`}>
      {/* {prev && <Link to={prev.uri} className={`prev`}>Previous Project</Link>} */}
      <Link to={`/our-work`} className={`button`}>All Projects</Link>
      {/* {next && <Link to={next.uri} className={`next`}>Next Project</Link>} */}
    </section>
  )
}

export default ProjectNavigation